<template>
  <div>
    <b-button
      @click="$router.go(-1)"
      variant="outline-warning"
      class="btn-icon my-1"
      size="sm"
    >
      <span class="">RETOUR</span>
    </b-button>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <b-card no-body title="" class="mt-1">
          <div class="my-2 mx-2 row">
            <!-- search start -->
            <div>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Recherche"
              >
              </b-form-input>
            </div>
            <!-- search end -->
          </div>

          <!-- table start -->
          <b-table
            id="my-table"
            responsive
            :fields="headers"
            :items="virements"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            show-empty
            class="border-bottom"
          >
            <template #cell(solde)="data">
              {{ $thousandSeparator(data.item.solde) }}
            </template>

            <!-- iconAction start -->
            <template #cell(actions)="data">
              <div class="row">
                <div>
                  <b-button
                    :id="'button-1' + data.item.id"
                    size="sm"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="detailsManifeste(data.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <!-- <b-tooltip
                    :target="'button-1' + data.item.id"
                    title="Details"
                    variant="dark"
                  ></b-tooltip> -->
                </div>

                <div>
                  <b-button
                    :id="'button-3' + data.item.id"
                    :disabled="
                      data.item.par_defaut == 1 || data.item.solde != 0
                    "
                    size="sm"
                    variant="flat-danger"
                    class="btn-icon"
                    @click="deleteManifeste(data.item)"
                    ref="button-3"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                  <!-- <b-tooltip
                    :target="'button-3' + data.item.id"
                    title="Supprimer"
                    variant="dark"
                  ></b-tooltip> -->
                </div>
              </div>
            </template>
            <!-- iconAction end -->

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <span class="text-nowrap mr-2"> Montré de 1 à </span>
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2"> de {{ totalRows }} entrées </span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BTooltip,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BIconCircleFill,
  VBTooltip,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BFormDatepicker,
    BIconCircleFill,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },

  data() {
    return {
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      perPage: 10,
      currentPage: 1,
      filter: "",
      pageOptions: [10, 25, 50, 100, { value: 1000000, text: "Tout" }],
      virements: [],
      headers: [
        // {
        //   key: "numero_manif",
        //   label: "Numéro",
        //   sortable: true,
        // },
        {
          key: "libelle",
          label: "Libellé",
          sortable: true,
          // class: "text-center",
        },
        {
          key: "solde",
          label: "Solde",
          sortable: true,
        },
        {
          key: "devise",
          label: "Devise",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    totalRows() {
      // Set the initial number of items
      return this.virements.length;
    },
  },

  created() {
    this.getVirements();
  },

  methods: {
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    getVirements() {
      this.$http.get("/caisse-principale-resources").then((res) => {
        if (res.status) {
          this.hiddenLoader = true;
        }

        this.virements = res.data.data;

        if (this.virements.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }
      });
    },

    detailsManifeste(item) {
      window.sessionStorage.setItem("virement_data", JSON.stringify(item));
      this.$router.push({
        name: "banque-virement-details",
      });
    },

    deleteManifeste(item) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des virements ?",
          {
            title: "Suppression",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/comptes/" + item.id)
              .then((result) => {
                if (result.data.success) {
                  this.showToast(
                    "Suppression effectuée avec succès",
                    "success"
                  );
                  this.getVirements();
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response, "danger");
              });
          }
        });
    },
  },
};
</script>

<style lang="scss">
#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.b-form-date-controls {
  margin-right: 38%;
}
</style>
